import { Drawer, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import SearchField from 'components/controls/SearchField';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'utils/i18n';
import { useLazyListVisibleQuery } from 'store/app/entities/EmployeesApiSlice';
import channelDrawer from 'store/app/ui/drawers/channel/action';
import directDrawer from 'store/app/ui/drawers/direct/action';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import useActions from 'utils/useActions';
import useDebouncePromise from 'utils/useDebouncePromise';
import CloseButton from '../CloseButton';
import DirectsList from './DirectsList';
import NewChannelArea from './NewChannelArea';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minWidth: 650,
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    padding: 20,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  headline: {
    paddingBottom: 25,
  },

  letter: {
    paddingLeft: 8,
  },
}));

const DirectDrawer = () => {
  const employeesRef = useRef({});
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [handleClose, openCreateChannel] = useActions([directDrawer.close, channelDrawer.open]);
  const isOpen = useSelector(state => state.drawers.direct.isOpen);
  const [triggerListVisibleQuery] = useLazyListVisibleQuery();
  const [isLoading, setLoading] = useState(true);
  const [isFetching, setFetching] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const listVisibleQuery = useDebouncePromise(
    useCallback(
      (...args) => {
        setFetching(true);
        return triggerListVisibleQuery(...args)
          .unwrap()
          .then(result => {
            setTotalCount(result.metadata.total);
            result.employees?.forEach((e, i) => {
              employeesRef.current[result.metadata.skip + i] = e;
            });
            return result;
          })
          .finally(() => {
            setFetching(false);
            setLoading(false);
          });
      },
      [triggerListVisibleQuery],
    ),
    300,
  );

  useEffect(() => {
    listVisibleQuery({
      search,
      skip: 0,
      limit: 99,
    });
  }, [search, listVisibleQuery]);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      setTotalCount(0);
      setSearch('');
      listVisibleQuery({
        search: '',
        skip: 0,
        limit: 99,
      });
    }
  }, [isOpen, listVisibleQuery]);

  const handleSearch = useCallback(event => {
    setSearch(event.target.value);
  }, []);

  const loadMoreItems = useCallback(
    (startIndex, stopIndex) => {
      return listVisibleQuery({
        search,
        skip: startIndex,
        limit: stopIndex - startIndex + 1,
      });
    },
    [listVisibleQuery, search],
  );

  if (!isOpen) return null;

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          <Typography variant="h5" className={classes.headline}>
            {I18n.t('EmployeesDrawer.New Chat')}
          </Typography>
          <SearchField onChange={handleSearch} />
          {isCompanyAdmin && <NewChannelArea onClick={openCreateChannel} />}
          {!isLoading || <Loading />}
          {isLoading || !totalCount || (
            <DirectsList
              isFetching={isFetching}
              employeesRef={employeesRef}
              loadMoreItems={loadMoreItems}
              totalCount={totalCount}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default memo(DirectDrawer);
