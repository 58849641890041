import { Button, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ImportsDrawer from 'components/@home/@people/ImportsDrawer';
import Alert from 'components/alerts/Alert';
import FileUpload from 'mdi-react/FileUploadIcon';
import ms from 'ms';
import { object } from 'prop-types';
import React, { useRef, useState } from 'react';
import { I18n } from 'utils/i18n';
import connector from './connector';

const styles = theme => ({
  root: {
    marginRight: 10,
    color: theme.palette.primary.ultraDark,
  },
  plus: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  form: {
    display: 'none',
  },
  close: {
    marginRight: -12,
  },
});

const autoHideDuration = ms('10 seconds');

const ImportEmployeesButton = ({ actions, classes }) => {
  const fileInput = useRef();
  const form = useRef();
  const alertInitialState = { isOpen: false };
  const [alert, setAlert] = useState(alertInitialState);
  const onClose = () => {
    setAlert(a => ({ ...a, ...alertInitialState }));
  };
  const handleChange = async event => {
    try {
      const {
        value: {
          data: { created, updated },
        },
      } = await actions.employees.createImport(event.target.files[0]);
      setAlert({
        isOpen: true,
        variant: 'success',
        message: I18n.t('ImportUsers.Success'),
        caption: I18n.t('ImportUsers.CreatedUpdated', { created, updated }),
      });
    } catch (e) {
      setAlert({
        isOpen: true,
        variant: 'error',
        message: e.data?.message || I18n.t('AttachFile.Errors.Unexpected error'),
        caption: '',
      });
    }
  };
  return (
    <>
      <Button
        component={Button}
        variant="outlined"
        color="secondary"
        className={classes.root}
        onClick={actions.drawers.employeeImports.open}
      >
        <SvgIcon className={classes.plus}>
          <FileUpload />
        </SvgIcon>
        {I18n.t('PeoplesScene.Import employees')}
      </Button>
      <form className={classes.form} ref={form}>
        <input
          ref={fileInput}
          onChange={handleChange}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          type="file"
        />
      </form>
      <Alert
        isOpen={alert.isOpen}
        variant={alert.variant}
        onClose={onClose}
        label={I18n.t('PeoplesScene.Import employees')}
        caption={alert.caption}
        autoHideDuration={autoHideDuration}
      >
        {alert.message}
      </Alert>
      <ImportsDrawer />
    </>
  );
};

ImportEmployeesButton.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
};

export default withStyles(styles)(connector(ImportEmployeesButton));
