import { createApi } from '@reduxjs/toolkit/query/react';
import Employee from 'services/api/Employee';
import baseQuery from 'store/app/entities/baseQuery';

const initialState = {
  employees: [],
};

let api;

const apiEndpoints = build => ({
  get: build.query({
    query: _id => `v1/employee/${_id}`,
    providesTags: (result, error, _id) => [{ type: 'Employee', _id }],
  }),
  getNames: build.query({
    query: body => ({
      url: 'v1/employee/names',
      method: 'POST',
      body,
    }),
  }),
  listVisible: build.query({
    query: ({ search = '', skip = 0, limit = 99, order = 'name', filters, team, group } = {}) => ({
      url: `v1/company/current/visible-employees${
        search ? `?search=${search}&` : '?'
      }skip=${skip}&limit=${limit}&order=${order}&${
        filters ? `filters=${JSON.stringify(filters)}` : ''
      }${team ? `&team=${team}` : ''}${group ? `&group=${group}` : ''}`,
      method: 'GET',
    }),
    transformResponse: response => response.data,
    providesTags: result =>
      result?.employees
        ? [
            ...result.employees.map(({ _id }) => ({ type: 'Employee', _id })),
            { type: 'Employee', _id: 'LIST' },
          ]
        : [{ type: 'Employee', _id: 'LIST' }],
  }),
  list: build.query({
    query: ({
      companyId,
      search = '',
      skip = 0,
      limit = 10,
      order = 'name',
      filters = {},
      team,
    }) => ({
      url: `v1/company/${companyId}/employees${
        search ? `?search=${search}&` : '?'
      }skip=${skip}&limit=${limit}&order=${order}&filters=${JSON.stringify(filters)}${
        team ? `&team=${team}` : ''
      }`,
      method: 'GET',
    }),
    transformResponse: response => response.data.employees,
    providesTags: result =>
      result?.data
        ? [
            ...result.data.map(({ _id }) => ({ type: 'Employee', _id })),
            { type: 'Employee', _id: 'LIST' },
          ]
        : [{ type: 'Employee', _id: 'LIST' }],
  }),
  block: build.mutation({
    query: ({ _id, isActive }) => ({
      url: `v1/employee/${_id}/block/${isActive}`,
      method: 'PATCH',
    }),
    async onQueryStarted({ _id, isActive }, { dispatch, getState, queryFulfilled }) {
      const arr = api.util.selectInvalidatedBy(getState(), [{ type: 'Employee', _id }]);
      const patchResults = arr.map(item =>
        dispatch(
          api.util.updateQueryData(item.endpointName, item.originalArgs, draft => {
            // eslint-disable-next-line no-param-reassign
            const found = draft.data?.find(employee => employee._id === _id);
            if (found) found.isActive = isActive;
          }),
        ),
      );
      try {
        await queryFulfilled;
      } catch {
        patchResults.forEach(patchResult => patchResult.undo());
      }
    },
  }),
  delete: build.mutation({
    query: ({ _id }) => ({
      url: `v1/employee/${_id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [{ type: 'Employee', _id: 'LIST' }],
  }),
  update: build.mutation({
    query: ({ _id, ...body }) => {
      return {
        url: `v1/employee/${_id}`,
        method: 'PATCH',
        body: Employee.createForm(body),
      };
    },
    async onQueryStarted({ _id, ...body }, { dispatch, getState, queryFulfilled }) {
      // Select the cache entries that would be invalidated by this update.
      const cacheEntries = api.util.selectInvalidatedBy(getState(), [{ type: 'Employee', _id }]);

      // Optimistically update these entries.
      const patchResults = cacheEntries.map(item =>
        dispatch(
          api.util.updateQueryData(item.endpointName, item.originalArgs, draft => {
            const found = draft.data?.find(employee => employee._id === _id);
            if (found) {
              // Apply all changes from patchData to this employee.
              Object.assign(found, body);
            }
          }),
        ),
      );
      try {
        // Await for the query to be fulfilled to confirm changes
        const { data: serverData } = await queryFulfilled;
        // Update the cache with the server's response data.
        cacheEntries.forEach(item => {
          dispatch(
            api.util.updateQueryData(item.endpointName, item.originalArgs, draft => {
              const employee = draft.data?.find(e => e._id === _id);
              if (employee && serverData.data?.employee) {
                // Replace the employee data with the fresh data from the server
                Object.assign(employee, serverData.data.employee);
              }
            }),
          );
        });
      } catch {
        // Undo optimistic updates if the mutation fails
        patchResults.forEach(patchResult => patchResult.undo());
      }
    },
  }),
  updateSettings: build.mutation({
    query: ({ _id, settings }) => ({
      url: `v1/employee/${_id}/settings`,
      method: 'PATCH',
      body: settings,
    }),
    async onQueryStarted({ _id, settings }, { dispatch, getState, queryFulfilled }) {
      const arr = api.util.selectInvalidatedBy(getState(), [{ type: 'Employee', _id }]);
      const patchResults = arr.map(item =>
        dispatch(
          api.util.updateQueryData(item.endpointName, item.originalArgs, draft => {
            // eslint-disable-next-line no-param-reassign
            const found = draft.data?.find(employee => employee._id === _id);
            if (found) {
              found.settings = settings;
            }
          }),
        ),
      );
      try {
        await queryFulfilled;
      } catch {
        patchResults.forEach(patchResult => patchResult.undo());
      }
    },
  }),
  create: build.mutation({
    query: body => ({
      url: 'v1/employee',
      method: 'POST',
      body: Employee.createForm(body),
    }),
    invalidatesTags: [{ type: 'Employee', _id: 'LIST' }],
  }),
  invitePreview: build.query({
    query: ({ companyId, search = '', filters }) => ({
      url: `v1/company/${companyId}/employees/invite-preview${
        search ? `?search=${search}&` : '?'
      }filters=${JSON.stringify(filters)}`,
      method: 'GET',
    }),
    transformResponse: response => response.data,
  }),
  invite: build.mutation({
    query: ({ companyId, search = '', filters }) => ({
      url: `v1/company/${companyId}/employees/invite`,
      method: 'POST',
      body: { search, filters },
    }),
    transformResponse: response => response.data,
  }),
});

api = createApi({
  reducerPath: 'employeesApi',
  baseQuery,
  tagTypes: ['Employee'],
  initialState,
  endpoints: build => apiEndpoints(build),
  keepUnusedDataFor: 30,
});

export const {
  useBlockMutation,
  useDeleteMutation,
  useLazyGetNamesQuery,
  useGetQuery,
  useInviteMutation,
  useInvitePreviewQuery,
  useListQuery,
  useLazyListQuery,
  useListVisibleQuery,
  useLazyListVisibleQuery,
  useUpdateSettingsMutation,
  endpoints: employeesApiEndpoints,
  middleware: employeesApiMiddleware,
  reducer: employeesApiReducer,
  util: { invalidateTags: invalidateEmployeesTags },
} = api;
