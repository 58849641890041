/* eslint-disable operator-linebreak,react/no-array-index-key */
import { SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import ArticleSnippet from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/ArticleSnippet';
import formatMentions from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/formatMentions';
import Acknowledgement from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/Message/Acknowledgement';
import MessageBlocks from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/MessageBlocks';
import MessageReplied from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/MessageReplied';
import WhatsAppTextFormatter from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/WhatsAppTextFormatter';
import * as linkify from 'linkifyjs';
import Linkify from 'linkifyjs/react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import ForbidIcon from 'mdi-react/ForbidIcon';
import ShareIcon from 'mdi-react/ShareIcon';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { I18n } from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import AdminMenu from './AdminMenu';
import Attachments from './Attachments';
import connector from './connector';
import EmployeeLabel from './EmployeeLabel';
import LinkPreview from './LinkPreview';
import Message from './Message';
import MessageLabel from './MessageLabel';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: -20,
    marginRight: -20,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: '0.88235294rem',
  },
  selected: {
    backgroundColor: 'rgba(227, 228, 252, 0.5)',
  },
  text: {
    color: theme.palette.text.primary,
    padding: '11.5px 30px 11.5px 15px',
    whiteSpace: 'pre-wrap',
    '& a': {
      color: theme.palette.link.main,
      '&:hover, &:active': {
        color: theme.palette.link.hover,
        textDecoration: 'underline',
      },
    },
    '& p': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      margin: 0,
    },
    '& hr': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      border: 0,
      width: '100%',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& blockquote': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      paddingLeft: 16,
      position: 'relative',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      paddingBlockStart: theme.spacing(0.5),
      paddingBlockEnd: theme.spacing(0.5),
      marginInlineStart: 0,
      marginInlineEnd: 0,
      whiteSpace: 'normal',
      '&:before': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        display: 'block',
        width: theme.spacing(0.5),
        borderRadius: theme.spacing(1),
        background: theme.palette.secondary.light,
        content: `''`,
      },
    },
  },
  deleted: {
    fontSize: 16,
    color: theme.palette.text.primary,
    padding: '11.5px 30px 11.5px 15px',
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'oblique',
  },
  deletedIcon: {
    marginRight: 4,
    fontSize: 17,
    opacity: 0.6,
  },
  bubble: {
    display: 'flex',
    flexDirection: 'row',
  },
  time: {
    marginBottom: 10,
    marginTop: 15,
  },
  line: {
    borderBottomWidth: 1,
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
    marginTop: 15,
    marginBottom: 10,
    fontSize: '0.8125rem',
    textAlign: 'center',
    borderBottomStyle: 'solid',
  },
  mention: {
    padding: '0 2px',
    minHeight: 'auto',
    display: 'inline',
    borderRadius: 3,
    color: theme.palette.mention,
    backgroundColor: theme.palette.mentionBack,
    border: 'none',
    fontSize: 'inherit',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'min-content',
    minWidth: 100,
  },
  imageWrapperText: {
    minWidth: 320,
  },
  forwarded: {
    '& p': {
      fontSize: 12,
      color: theme.palette.secondary.main,
    },
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
  },
  forwardedIcon: {
    color: theme.palette.secondary.main,
    fontSize: 20,
  },
}));

const MessageGroup = ({
  actions,
  authEmployee,
  message: messageIn,
  availableEmployees,
  channel,
  line,
  msgRef,
  isSelected,
  scrollToMessage,
}) => {
  const classes = useStyles();
  const [hover, setHoverValue] = useState(false);
  const [editing, setEditing] = useState(false);
  const navigate = useNavigate();

  const avatar = messageIn.employee?.avatar;

  const message = useMemo(
    () => ({
      ...messageIn,
      avatar: messageIn.sentAsChannel ? channel.avatar : avatar,
      name:
        messageIn.sentAsChannel && messageIn.employeeLabel ? channel.name : messageIn.employeeLabel,
      nameNotBlank: messageIn.sentAsChannel ? channel.name : messageIn.employeeName,
    }),
    [avatar, channel?.avatar, channel?.name, messageIn],
  );

  useEffect(() => {
    if (message.text && isEmpty(message.previews)) {
      const urls = linkify
        .tokenize(message.text)
        .filter(t => t.isLink)
        .map(t => t.toObject('https'));
      urls.forEach(url => {
        if (message.temp || url.type !== 'url') return;
        actions.messages.generatePreview(url.href, message._id);
      });
    }
  }, [actions.messages, message]);

  const editClicked = () => {
    setEditing(true);
  };

  const editCanceled = () => {
    setEditing(false);
  };

  const deleteConfirmed = () => {
    actions.messages.destroy(message._id);
  };

  const setHover = h => () => setHoverValue(h);

  const formatMentionNode = (txt, employee, key) => {
    const openChannel = () => {
      if (employee.user?._id && employee.isAcceptTerms) {
        navigate(`/home/messages/${employee.user?._id}`);
      }
    };
    return (
      <button key={key} type="button" onClick={openChannel} className={classes.mention}>
        {txt}
      </button>
    );
  };

  if (message?.isDeleted) {
    return null;
  }

  const isImage = message.attachments?.[0]?.mimeType?.startsWith('image/');
  return (
    <div
      className={classNames({ [classes.root]: true, [classes.selected]: isSelected })}
      ref={msgRef}
    >
      {line ? <div className={classes.line}>{I18n.t('MessagesList.Unread')}</div> : null}
      <MessageLabel className={classes.time}>
        {!message.temp ? message.dateTime : null}
      </MessageLabel>
      {!message.article && <EmployeeLabel right={message.isMine}>{message.name}</EmployeeLabel>}
      <Message message={message} availableEmployees={availableEmployees}>
        <div
          className={classes.bubble}
          onMouseEnter={setHover(true)}
          onMouseLeave={setHover(false)}
          onFocus={setHover(true)}
          onBlur={setHover(false)}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {message?.softDeletedAt ? (
            <div className={classes.deleted}>
              <SvgIcon className={classes.deletedIcon}>
                <ForbidIcon />
              </SvgIcon>
              <div>{I18n.t('Message.deleted')}</div>
            </div>
          ) : !message?.isMandatory && !message?.form ? (
            !editing && (
              <div
                className={classNames({
                  [classes.imageWrapper]: isImage,
                  [classes.imageWrapperText]:
                    isImage && message.text?.trim().replace('attachments', ''),
                })}
              >
                {!!message.showForwarded && (
                  <div className={classes.forwarded}>
                    <SvgIcon className={classes.forwardedIcon}>
                      <ShareIcon />
                    </SvgIcon>
                    <Typography>{I18n.t('Message.forwarded')}</Typography>
                  </div>
                )}
                {Object.values(message.previews || {}).map((preview, index) => (
                  <LinkPreview key={index} preview={preview} />
                ))}
                {(message.blocks?.length || (message.text && message.text !== 'attachments')) && (
                  <div className={classes.text}>
                    {!!message.text && (
                      <WhatsAppTextFormatter>
                        <Linkify options={{ target: '_blank' }}>
                          {formatMentions(
                            message.text,
                            message.mentions,
                            availableEmployees,
                            formatMentionNode,
                          )}
                        </Linkify>
                      </WhatsAppTextFormatter>
                    )}
                    <MessageBlocks message={message} />
                  </div>
                )}
                <ArticleSnippet message={message} />
                <Attachments attachments={message.attachments} right={message.isMine} />
                <MessageReplied message={message} scrollToMessage={scrollToMessage} />
              </div>
            )
          ) : (
            <Acknowledgement message={message} />
          )}
          <AdminMenu
            visible={hover}
            message={message}
            canReply={channel?.isAdmin || !channel?.isReadOnly}
            onEditClick={editClicked}
            onEditCanceled={editCanceled}
            onDeleteConfirmed={deleteConfirmed}
          />
        </div>
      </Message>
    </div>
  );
};

MessageGroup.defaultProps = {
  line: false,
};

MessageGroup.propTypes = {
  authEmployee: employeeShape.isRequired,
  availableEmployees: arrayOf(employeeShape).isRequired,
  msgRef: object.isRequired,
  isSelected: bool.isRequired,
  line: bool,
  actions: object.isRequired,
  channel: channelShape.isRequired,
  message: shape({
    text: string,
    seen: bool,
    isMandatory: bool,
    error: bool,
    loading: bool,
    employee: shape({
      name: string,
      avatar: string,
    }),
    dateTime: string,
    line: bool,
  }).isRequired,
  scrollToMessage: func.isRequired,
};

const areEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.message, nextProps.message) &&
    prevProps.msgRef === nextProps.msgRef &&
    prevProps.isSelected === nextProps.isSelected &&
    isEqual(prevProps.channel, nextProps.channel)
  );
};

export default connector(memo(MessageGroup, areEqual));
